/** @jsx jsx */
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { screenSize, colors } from "../../utils/css/themes";
import { jsx } from "@emotion/react";
import DangerousHtml from "../Helpers/DangerousHtml";

const LinkBuilder = (props: LinkBuilderProps) => {
  const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: column;
    justify-content: space-around;
    @media (min-width: ${screenSize.smallTablet}) {
      flex-direction: row;
      flex-flow: row;
    }
  `;

  const LinkStyle = styled((props) => <Link {...props} />)`
    white-space: wrap;
    max-width: 205px;
    margin-right: 5px;
    p {
      font-size: 18px;
      color: ${colors.blue};
      margin: 0.5rem 0;
      font-weight: 600;
    }
  `;

  const LinkColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: ${screenSize.smallTablet}) {
      flex-flow: column;
    }
    flex-flow: inherit;
  `;

  const pageType = props.type && props.type.type ? props.type.type : props.type;
  const divHeight = 10;
  const links = FilterLinks(pageType);
  const numberOfDivs = Math.ceil(links.length / divHeight);

  // TODO alex there's nothing wrong with this filtering, but the data we are querying now
  // TODO alex has way too many PEOPLE issuePageType pages. Could clean the wordpress data
  // TODO alex manually, or find a way to update the GraphQL query to get whatever field
  // TODO alex the production site is finding?
  function FilterLinks(type: String) {
    let linksArray = new Array();
    let addedLinks = new Array();

    props.issue_pages.forEach((link: any) => {
      if (
        link.issuePageType &&
        link.issuePageType.issuePageType &&
        link.issuePageType.issuePageType.some((r: any) => type.includes(r))
      ) {
        if (addedLinks && !addedLinks.includes(link.title)) {
          linksArray.push(link);
        }
        addedLinks.push(link.title);
      }
    });
    return linksArray;
  }

  function AddLinks(index: number, numOfDivs: number) {
    let returnArray = new Array();
    let exitNum = index * divHeight + links.length / numOfDivs;
    for (let i = index * divHeight; i < exitNum + 1; i++) {
      links[i] &&
        returnArray.push(
          <LinkStyle
            to={"/" + links[i].slug}
            key={i}
            state={{ issue_type: props.from_service_hub ? pageType : null }}
          >
            <DangerousHtml>{`<p>${links[i].title}</p>`}</DangerousHtml>
          </LinkStyle>
        );
    }
    return returnArray;
  }

  function BuildLinkColumns() {
    let returnArray = new Array();
    for (let i = 0; i < numberOfDivs; i++) {
      returnArray.push(
        <LinkColumn key={i}>{AddLinks(i, numberOfDivs)}</LinkColumn>
      );
    }
    return returnArray;
  }

  return <ColumnContainer>{BuildLinkColumns()}</ColumnContainer>;
};

export default LinkBuilder;

interface LinkBuilderProps {
  from_service_hub?: boolean;
  issue_pages: any;
  type: any;
}
