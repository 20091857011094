/** @jsx jsx */
/** @jsxFrag React.Fragment */
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ServiceHubExtNav from "../components/ServiceHub/ExtNav";
import { useState } from "react";

import { colors, screenSize } from "../utils/css/themes";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";

import Caret from "../utils/images/faq-carot.inline.svg";
import Accordion from "../components/Helpers/Accordion";
import LinkBuilder from "../components/ServiceHub/LinkBuilder";
import DangerousHtml from "../components/Helpers/DangerousHtml";
import SEO from "../components/seo";

const IssuePage = (props: any) => {
  const [toggleOpen, setToggleOpen] = useState(0);

  const AccordionTitle = styled.h2`
    margin-left: 2rem;
    margin-top: auto;
    margin-bottom: auto;
    color: ${colors.blue};
    font-family: Baloo;
    letter-spacing: -0.43px;
    width: 80%;
    text-align: left;

    @media (max-width: ${screenSize.largeDisplay}) {
      font-size: 26px;
      margin-left: 2rem;
    }

    @media (max-width: ${screenSize.largeTablet}) {
      font-size: 25px;
      margin-left: 2rem;
      text-align: left;
    }
    @media (max-width: ${screenSize.smallTablet}) {
      margin-left: 1rem;
      text-align: left;
      font-size: 23px;
    }
  `;

  const CaretContainerStyle = styled.span`
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
    border-radius: 100px;
    margin-top: auto;

    width: 28px;
    height: 28px;
    margin-bottom: auto;

    @media (min-width: ${screenSize.smallTablet}) {
      width: 43px;
      height: 43px;
      margin-right: 2rem;
    }
  `;

  const LinksContainer = styled.div`
    border-left: none;
    width: 100%;
    margin: 1.5rem 0 0 1rem;

    float: left;
    min-height: 247px;

    @media (min-width: ${screenSize.smallTablet}) {
      max-height: 450px;
      margin: 1.5rem 0 0 2rem;
    }
    @media (min-width: ${screenSize.largeTablet}) {
      max-height: 300px;
      margin: 1.5rem;
    }
  `;

  const CaretTitleContainer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
  `;

  const posts = props.data?.allWpPost?.nodes;
  // Sort the posts alphabetically by their "title" property.
  // NOTE: GraphQL should already be sorting alphabetically by title, however their is an intermittent
  // reoccuring bug, that sometimes applies the graphql sort when building, and
  // other times does not. I am hardcoding a sort in Javascript, to force the sort
  // everytime. -Christian Brooks
  posts.sort(function (a: any, b: any) {
    var textA = a.title.toUpperCase();
    var textB = b.title.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const post = props.data?.wpPost;

  const ErrorLayout = (props: any) => (
    <Layout
      backButton={{
        label: "Service Hub",
        link: "/service-hub",
      }}
      title="Service Hub"
    >
      <p>Could not retrieve data.</p>
    </Layout>
  );

  if (!posts) {
    return <ErrorLayout />;
  }
  if (!post) {
    return <ErrorLayout />;
  }

  const GenerateAccordion = () => {
    const labelText = "Other ways to help ";

    // Gets whatever the current issue page category is:
    // people, animals, environment, or community
    let issue_type = post.issuePageType.issuePageType[0];

    const accordionLabel = issue_type
      ? labelText + (issue_type && issue_type.toUpperCase())
      : labelText;
    const title = (
      <>
        <CaretTitleContainer
          css={css`
            background-color: ${1 === toggleOpen ? "#f1f2f6" : "white"};
          `}
        >
          <AccordionTitle>{accordionLabel}</AccordionTitle>
          <CaretContainerStyle
            css={css`
              background-color: ${1 === toggleOpen ? colors.blue : "white"};
              border: ${1 === toggleOpen
                ? "none"
                : "solid 2px " + colors.orange};
              @media (min-width: ${screenSize.smallTablet}) {
                border: ${1 === toggleOpen
                  ? "none"
                  : "solid 3px " + colors.orange};
              }
            `}
          >
            <Caret
              height={"20"}
              width={"20"}
              css={css`
                transform: ${0 === toggleOpen ? "rotate(-180deg)" : "none"};

                path,
                g {
                  stroke: ${1 === toggleOpen ? "white" : colors.orange};
                  stroke-width: 1;
                }
                margin: auto;
              `}
            >
              &and;
            </Caret>
          </CaretContainerStyle>
        </CaretTitleContainer>
      </>
    );
    return (
      issue_type && (
        <Accordion
          isOpen={1 === toggleOpen}
          setIsOpen={() =>
            0 === toggleOpen ? setToggleOpen(1) : setToggleOpen(0)
          }
          title={title}
        >
          <LinksContainer>
            <LinkBuilder issue_pages={posts} type={issue_type} />
          </LinksContainer>
        </Accordion>
      )
    );
  };

  return (
    <Layout
      backButton={{
        label: "Service Hub",
        link: "/service-hub",
      }}
      title="Service Hub"
    >
      <SEO title={"Service Hub"} />
      <h1>{post.title || "Not Found"}</h1>
      <DangerousHtml>{post?.content}</DangerousHtml>
      <ServiceHubExtNav {...post}></ServiceHubExtNav>
      {GenerateAccordion()}
    </Layout>
  );
};
export default IssuePage;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      ...issuePost
    }
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Issue Page" } } } }
      }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        title
        slug
        issuePageType {
          issuePageType
        }
      }
    }
  }
`;
